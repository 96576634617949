<template>
  <div class="page">
    <!-- 基本信息 -->
    <div class="essential_information_box">
      <div class="box">
        <div class="top_box">
          <div class="title">基本信息</div>
          <div class="line"></div>
          <div class="status_box">
            <p v-if="detail.status == 4">已完成<span class="green"></span></p>
            <p v-else-if="detail.status == 2">
              已接单<span class="blue"></span>
            </p>
            <p v-else-if="detail.status == 1">
              待受理<span class="red"></span>
            </p>
            <p v-else>处理中<span class="yellow"></span></p>
          </div>
        </div>
        <div class="content_box">{{ detail.eventDescription }}</div>
        <div class="pic_box">
          <div v-for="(item, index) in picList2" :key="index">
            <img @click="showImgClick(picList, index)" :src="item" alt="" />
          </div>

          <!-- <img src="./img/index/topPic.png" alt="">
                <img src="./img/index/topPic.png" alt=""> -->
        </div>
        <div class="detail_box">
          <div class="leabl">发布时间：</div>
          <div class="value">{{ detail.createTime }}</div>
        </div>
        <div class="detail_box">
          <div class="leabl">发布人：</div>
          <div class="value">{{ detail.name }}</div>
        </div>
        <div class="detail_box">
          <div class="leabl">事件类型：</div>
          <div class="value">{{ detail.incidentName }}</div>
        </div>
        <div class="detail_box">
          <div class="leabl">事件地点：</div>
          <div class="value">{{ detail.eventPlace }}</div>
        </div>
      </div>
    </div>
    <!-- 处理进度 -->
    <div class="processing_progress_box">
      <div class="box">
        <div class="top_box">
          <div class="title">处理进度</div>
          <div class="line"></div>
        </div>
        <div class="wait_box" v-if="detail.status == 1">
          您的工单还未接单，请您耐心等待～
        </div>
        <div class="step_box">
          <!-- 1 -->
          <div
            class="step_title_box"
            v-if="
              detail.status == 4 || detail.status == 2 || detail.status == 3
            "
          >
            <img
              v-if="detail.status == 2"
              src="./img/clapCasuallyDetail/thisStep.png"
              alt=""
            />
            <img v-else src="./img/clapCasuallyDetail/done.png" alt="" />
            工单接收
          </div>
          <div
            class="step_detail_box"
            v-if="
              detail.status == 4 || detail.status == 2 || detail.status == 3
            "
          >
            <p v-if="detail.receiveResponse != ''">
              {{ detail.receiveResponse }}
            </p>
            <p v-else>您的工单已接收，稍后将安排工作人员为您服务。</p>
            <div class="time">{{ detail.receiveTime }}</div>
          </div>
          <!-- 2 -->
          <div
            class="step_title_box"
            v-if="detail.status == 4 || detail.status == 3"
          >
            <img
              v-if="detail.status == 3"
              src="./img/clapCasuallyDetail/thisStep.png"
              alt=""
            />
            <img v-else src="./img/clapCasuallyDetail/done.png" alt="" />
            开始处理
          </div>
          <div
            class="step_detail_box"
            v-if="detail.status == 4 || detail.status == 3"
          >
            <p v-if="detail.disposeResponse != ''">
              {{ detail.disposeResponse }}
            </p>
            <p v-else>您的工单正在处理。</p>
            <div class="time">{{ detail.disposeTime }}</div>
          </div>
          <!-- 3 -->
          <div class="step_title_box" v-if="detail.status == 4">
            <img
              v-if="detail.status == 4"
              src="./img/clapCasuallyDetail/thisStep.png"
              alt=""
            />
            <img v-else src="./img/clapCasuallyDetail/done.png" alt="" />
            完成处理
          </div>
          <div class="step_detail_box" v-if="detail.status == 4">
            <p v-if="detail.finishResponse != ''">
              {{ detail.finishResponse }}
            </p>
            <p v-else>服务已完毕，请为本次服务打分/评价。</p>
            <div class="time">{{ detail.finishTime }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 评价打分 -->
    <div class="evaluate_box" v-if="detail.status == 4">
      <div class="box">
        <div class="top_box">
          <div class="title">评价打分</div>
          <div class="line"></div>
        </div>
        <div class="satisfaction_box">
          <p>满意度：</p>
          <div
            class="option_box"
            @click="choiseHappy(item)"
            v-for="(item, index) in satisfactionList"
            :key="index"
          >
            <span :class="[{ click: item.select == true }]"></span
            >{{ item.name }}
          </div>
        </div>
        <div class="proposal_box">
          <div class="proposal_in_box">
            <van-field
              :disabled="status == false"
              style="background: transparent"
              v-model="message"
              type="textarea"
              placeholder="请写下您的意见建议："
              show-word-limit
            />
          </div>
        </div>
        <div
          class="btn"
          v-if="detail.status == 4 && status == true"
          @click="submit"
        >
          提交
        </div>
      </div>
    </div>

    <!-- 图片展示 -->
    <van-overlay :show="show">
      <div class="pic_big_box">
        <img :src="this.nowImg" alt="" />
      </div>
      <img
        class="close"
        @click="show = false"
        src="./img/clapCasuallyDetail/close.png"
        alt=""
      />
    </van-overlay>
    <v-picList
      :startPosition="startPosition"
      v-model="showImg"
      :imgList="imgList"
    >
    </v-picList>
  </div>
</template>

<script>
import { snapshotDetailsUrl, satisfactionEvaluationUrl } from "./api.js";
export default {
  name: "reportForRepairDetail",
  data() {
    return {
      show: false,
      message: "",
      happyPoints: null,
      detail: {},
      satisfactionList: [
        {
          name: "非常满意",
          points: 1,
          select: true,
        },
        {
          name: "满意",
          points: 2,
          select: false,
        },
        {
          name: "不满意",
          points: 3,
          select: false,
        },
      ],
      picList: [],
      picList2: [],
      startPosition: 0,
      showImg: false,
      imgList: [],
      id: null,
      nowImg: null,

      status: null,
    };
  },
  created() {
    this.id = Number(this.$route.query.id);
    console.log(this.id);
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    submit() {
      //提交按钮
      let params = {
        id: this.id,
        satisfaction: this.happyPoints,
        suggestion: this.message,
      };
      this.$axios
        .post(satisfactionEvaluationUrl, this.$qs.stringify(params))
        .then((res) => {
          console.log(res);
          if (res.code === 200) {
            this.$toast("提交成功！");
            setTimeout(() => {
              this.$router.go(-1);
            });
          }
        });
    },
    choiseHappy(data) {
      if (this.status == true) {
        this.satisfactionList.forEach((item, index) => {
          item.select = false;
        });
        data.select = true;
        this.happyPoints = data.points;
      } else {
      }
    },
    showPic(url) {
      this.nowImg = url;
      this.show = true;
    },
    getDetail() {
      let params = {
        userId: this.userId,
        id: this.id,
      };
      this.$axios
        .get(`${snapshotDetailsUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            console.log(res);
            this.detail = res.data;
            // this.detail.status = 4

            if (res.data.satisfaction != 0 || res.data.suggestion != "") {
              this.satisfactionList.forEach((item) => {
                item.select = false;
                if (item.points == res.data.satisfaction) {
                  item.select = true;
                }
              });
              this.happyPoints = res.data.satisfaction;
              this.message = res.data.suggestion;
              this.status = false;
            } else if (
              res.data.satisfaction == 0 &&
              res.data.suggestion == ""
            ) {
              this.status = true;
            }
            this.picList = this.detail.eventPicture.split(",");
            this.picList2 = this.picList;
            let list = [];
            this.picList2.forEach((item) => {
              list.push(this.$handleImg(186, 186, item));
            });
            this.picList2 = list;
          }
        });
    },
    showImgClick(list, index) {
      this.showImg = true;
      this.imgList = list;
      this.startPosition = index;
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  min-height: 100vh;
  background: #fafafa;
  padding-top: 24px;
  padding-bottom: 50px;
  box-sizing: border-box;

  .close {
    width: 76px;
    height: 76px;
    margin: 268px auto 0;
    display: block;
  }

  .pic_big_box {
    margin-top: 144px;
    width: 100%;
    padding: 0 63px;
    height: 536px;
    box-sizing: border-box;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .evaluate_box {
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
    margin-top: 38px;

    .box {
      width: 100%;
      // height: 100%;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
      border-radius: 16px;
      box-sizing: border-box;
      padding-bottom: 26px;

      .btn {
        box-sizing: border-box;
        text-align: center;
        line-height: 66px;
        width: 333px;
        height: 66px;
        background: linear-gradient(180deg, #72a8ff 0%, #3680ff 100%);
        border-radius: 10px;
        font-size: 32px;
        font-weight: 600;
        color: #ffffff;
        margin: 0 auto;
        margin-top: 20px;
      }

      .proposal_box {
        margin-top: 16px;
        box-sizing: border-box;
        padding: 0 44px;
        // height: 98px;

        .proposal_in_box {
          width: 100%;
          height: 100%;
          background: rgba(247, 252, 255, 0.39);
          // background: red;
        }
      }

      .satisfaction_box {
        display: flex;
        font-size: 26px;
        color: #333333;
        padding: 0 44px;
        box-sizing: border-box;

        p {
          width: 110px;
          white-space: nowrap;
        }

        .option_box {
          align-items: center;
          margin-right: 36px;

          span {
            box-sizing: border-box;
            width: 20px;
            height: 20px;
            border: 2px solid #3680ff;
            border-radius: 50%;
            display: inline-block;
            margin-right: 6px;
          }

          .click {
            background-color: #3680ff;
          }
        }
      }

      .top_box {
        width: 100%;
        height: 74px;
        margin-bottom: 24px;
        position: relative;

        .title {
          font-size: 36px;
          font-weight: 600;
          color: #333333;
          position: absolute;
          bottom: 0;
          left: 44px;
          z-index: 1;
        }

        .line {
          width: 182px;
          height: 14px;
          background: #e2e2e2;
          border-radius: 7px;
          position: absolute;
          bottom: 0;
          left: 26px;
        }
      }
    }
  }

  .processing_progress_box {
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;

    .box {
      width: 100%;
      height: 100%;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
      border-radius: 16px;
      box-sizing: border-box;
      padding-bottom: 30px;

      .step_box {
        box-sizing: border-box;
        width: 100%;
        padding: 0 44px;

        .step_title_box {
          font-size: 30px;
          color: #333333;
          display: flex;
          align-items: center;

          img {
            width: 16px;
            height: 18px;
            margin-right: 12px;
          }
        }

        .step_detail_box {
          margin-left: 6px;
          box-sizing: border-box;
          height: 77px;
          border-left: 4px solid #3680ff;
          padding-left: 18px;

          p {
            white-space: nowrap;
            font-size: 26px;
            color: #6d7278;
            margin-bottom: 5px;
          }

          .time {
            font-size: 24px;
            color: rgba(0, 0, 0, 0.25);
          }
        }

        .step_detail_box:nth-last-child(1) {
          border: none;
        }
      }

      .wait_box {
        font-size: 30px;
        color: #333333;
        padding: 24px 44px;
      }

      .top_box {
        width: 100%;
        height: 74px;
        margin-bottom: 24px;
        position: relative;

        .title {
          font-size: 36px;
          font-weight: 600;
          color: #333333;
          position: absolute;
          bottom: 0;
          left: 44px;
          z-index: 1;
        }

        .line {
          width: 182px;
          height: 14px;
          background: #e2e2e2;
          border-radius: 7px;
          position: absolute;
          bottom: 0;
          left: 26px;
        }
      }
    }
  }

  .essential_information_box {
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
    margin-bottom: 26px;

    .box {
      width: 100%;
      height: 100%;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
      border-radius: 16px;
      box-sizing: border-box;
      padding-bottom: 30px;

      .detail_box {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding: 0 44px;
        margin-bottom: 10px;

        .leabl {
          width: 140px;
          font-size: 26px;
          color: #333333;
          white-space: nowrap;
        }

        .value {
          word-break: break-all;
          flex: 1;
          font-size: 26px;
          color: #6d7278;
        }
      }

      .pic_box {
        box-sizing: border-box;
        width: 100%;
        height: 186px;
        display: flex;
        padding: 0px 46px 0 44px;
        margin-bottom: 26px;

        div {
          width: 186px;
          height: 186px;
          border-radius: 10px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }

        div:nth-child(2) {
          margin: 0 20px 0 22px;
        }
      }

      .content_box {
        box-sizing: border-box;
        font-size: 28px;
        color: #333333;
        padding: 10px 30px 10px 44px;
      }

      .top_box {
        box-sizing: border-box;
        width: 100%;
        height: 74px;
        position: relative;

        .title {
          font-size: 36px;
          font-weight: 600;
          color: #333333;
          position: absolute;
          bottom: 0;
          left: 44px;
          z-index: 1;
        }

        .line {
          width: 182px;
          height: 14px;
          background: #e2e2e2;
          border-radius: 7px;
          position: absolute;
          bottom: 0;
          left: 26px;
          z-index: 0;
        }

        .status_box {
          position: absolute;
          right: 24px;
          top: 30px;

          p {
            font-size: 26px;
            color: rgba(0, 0, 0, 0.5);
            text-align: right;

            span {
              width: 10px;
              height: 10px;

              border-radius: 50%;
              display: inline-block;
              position: relative;
              top: -10px;
              right: -2px;
            }

            .green {
              background: #66b95a;
            }

            .blue {
              background: #8db0e8;
            }

            .red {
              background: #c33333;
            }

            .yellow {
              background: #f9980b;
            }
          }
        }
      }
    }
  }
}
</style>
